
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
import { Modal } from "bootstrap";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      detailModal: "",
      UserWalletWithdrowRequests: [],
      drivers: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchUserWalletWithdrowRequests: null,
      UserWalletWithdrowRequestsSearchMode: false,
      searchModel: "",
      state: state,
      withdrawMethod: "bank",
      amount: 0,
      cardNumber: "",
      expiryDate: "",
    };
  },
    beforeUnmount() {
      // Remove event listener when component is destroyed
      document.removeEventListener("click", this.handleClickOutside);
    },
 
  methods: {
  
   handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.closeModal();
      }
    },
    showModel() {
      this.detailModal.show();
      // this.popup.modal(data);
    },
    closeModel() {
      this.detailModal.hide();
      // this.popup.modal(data);
    },
    submitForm() {
      // Here you can submit the form data, for example, by making an API request
      console.log("Withdrawal Method:", this.withdrawMethod);
      console.log("Amount:", this.amount);
      // Reset the form
      this.withdrawMethod = "bank";
      this.amount = 0;
      this.showModal = false;
    },
    addUserWalletWithdrowRequests() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addUserWalletWithdrowRequests",
        inputs: [
          { model: "user_id", type: "text", label: "user_id" },
          { model: "amount", type: "text", label: "amount" },
          { model: "status_id", type: "text", label: "status_id" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("user-wallet-withdrow-requests", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editUserWalletWithdrowRequests(app) {
      var data = {
        title: "popups.user_wallet_withdrow_requests",
        inputs: [
          {
            model: "user_id",
            type: "text",
            label: "user_id",
            value: app.user_id,
          },
          { model: "amount", type: "text", label: "amount", value: app.amount },
          {
            model: "status_id",
            type: "text",
            label: "status_id",
            value: app.status_id,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http
                .put("user-wallet-withdrow-requests", app.id, obj)
                .then(() => {
                  this.get(this.page);
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateUserWalletWithdrowRequests(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          { model: "user_id", type: "text", label: "user_id" },
          { model: "amount", type: "text", label: "amount" },
          { model: "status_id", type: "text", label: "status_id" },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("user-wallet-withdrow-requests", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.UserWalletWithdrowRequestsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("user-wallet-withdrow-requests/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.UserWalletWithdrowRequests = res.data;
          console.log("######## files", this.UserWalletWithdrowRequests);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.UserWalletWithdrowRequestsSearchMode = false;
      this.get(this.page);
    },

    getUserWalletWithdrowRequests() {
      this.http.get("user-wallet-withdrow-requests").then((res) => {
        this.UserWalletWithdrowRequests = res.data;
      });
    },
    deleteUserWalletWithdrowRequests(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("user-wallet-withdrow-requests", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("user-wallet-withdrow-requests/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.UserWalletWithdrowRequests = res.data.filter((item) => {
            if (item.user.user_type_id == 2) {
              return item;
            }
          });
          this.drivers = res.data.filter((item) => {
            if (item.user.user_type_id == 3) {
              return item;
            }
          });
        });
    },

    changeStatus(app, status) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http
            .put("user-wallet-withdrow-requests/", app.id, {
              status_id: status,
            })
            .then(() => {
              console.log("############################3");
              this.get(this.page);
            });
        }
      });
    },
  },
  created() {
    this.get(1);
  },
  mounted() {
    this.detailModal = new Modal(`#banner`);
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.withdrawal_request.text')"
    />

    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      id="banner"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Withdrawal Method</h5>
            <button type="button" class="close" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Withdrawal method selection -->
            <div class="form-group">
              <label for="withdrawMethod">{{$t("user_wallet_withdrow_requests.pick_method")}}</label>
              <select
                class="form-control"
                id="withdrawMethod"
                v-model="withdrawMethod"
              >
                <!-- <option value="bank">{{$t("user_wallet_withdrow_requests.bank")}}</option> -->
                <option value="paypal">{{$t("user_wallet_withdrow_requests.paypal")}}</option>
                <option value="visa">{{$t("user_wallet_withdrow_requests.visa")}}</option>
                <option value="mastercard">{{$t("user_wallet_withdrow_requests.master_card")}}</option>
                <!-- Add more user_wallet_withdrow_requestsal methods as needed -->
              </select>
            </div>
            <!-- Amount input -->
            <div class="form-group">
              <label for="amount">{{$t("user_wallet_withdrow_requests.amount")}}</label>
              <input
                type="number"
                class="form-control"
                id="amount"
                v-model.number="amount"
              />
            </div>
            <!-- Card information form (visible only if Visa or MasterCard is selected) -->
            <div
              v-if="
                withdrawMethod === 'visa' || withdrawMethod === 'mastercard'
              "
            >
              <div class="form-group">
                <label for="cardNumber">{{$t("user_wallet_withdrow_requests.card_number")}}</label>
                <input
                  type="text"
                  class="form-control"
                  id="cardNumber"
                  v-model="cardNumber"
                />
              </div>
              <div class="form-group">
                <label for="expiryDate">{{$t("user_wallet_withdrow_requests.expire_date")}}</label>
                <input
                  type="text"
                  class="form-control"
                  id="expiryDate"
                  v-model="expiryDate"
                />
              </div>
              <!-- Add more card information fields as needed -->
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModel()">
              Close
            </button>
            <button type="button" class="btn btn-primary" @click="submitForm">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <b-tabs
      content-class="p-4"
      class="pt-2"
      nav-wrapper-class="nav-item"
      nav-class="justify-content-start nav-tabs-custom"
    >
      <!-- marketing channels -->

      <b-tab :title="$t('menu.menuitems.vendors.text')" active>
        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box w-50">
              <div class="position-relative">
                <input
                  @keyup="search()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  :placeholder="$t('popups.search')"
                  v-model="searchModel"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
              </div>
            </div>
          </div>
        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-3">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("user_wallet_withdrow_requests.id") }}</th>
              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.user_id") }}
              </th>

              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.tel") }}
              </th>

              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.email") }}
              </th>

              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.amount") }}
              </th>
              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.status_id") }}
              </th>
              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.created") }}
              </th>
              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.updated") }}
              </th>
              <th scope="col">
                {{ $t("popups.operations") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(app, index) in UserWalletWithdrowRequests"
              :key="app"
              class="text-center"
            >
              <td>{{ index + 1 }}</td>

              <td>{{ app?.user?.name }}</td>
              <td>{{ app?.user?.tel }}</td>
              <td>{{ app?.user?.email }}</td>
              <td>{{ app.amount }}</td>
              <td>
                {{
                  $i18n.locale == "ar"
                    ? app?.status?.name_ar
                    : app?.status?.name
                }}
              </td>
              <td>{{ app.created.split("T")[0] }}</td>
              <td>{{ app.updated.split("T")[0] }}</td>

              <td class="d-flex">
                <a
                  @click="showModel()"
                  class="btn btn-success mx-2"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.accept") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="changeStatus(app, 3)"
                  >{{ $t("popups.reject") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>

        <!-- end  
      
       <a v-if="app.status == 1" class="btn btn-danger mx-1" @click="changeStates(app)" href="javascript: void(0);"
              role="button">{{ $t("popups.disable") }}</a>
            <a v-if="app.status == 0" class="btn btn-primary mx-1" @click="changeStates(app)" href="javascript: void(0);"
              role="button">{{ $t("popups.enable") }}</a>
      
        table-->
        <!-- Button trigger modal -->

        <div v-if="tot_pages == 20">
          <!--   Apps  paginate     -->
          <ul class="paginate paginate-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: p == page }"
              v-for="p in tot_pages"
              :key="p"
            >
              <a class="page-link" href="javascript: void(0);" @click="get(p)">
                {{ p }}
              </a>
            </li>

            <li class="page-item" :class="{ disabled: page == total_pages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </b-tab>
      <b-tab :title="$t('menu.menuitems.drivers.text')">
        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box w-50">
              <div class="position-relative">
                <input
                  @keyup="search()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  :placeholder="$t('popups.search')"
                  v-model="searchModel"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
              </div>
            </div>
          </div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button
              class="btn-close text-light"
              v-if="UsersSearchMode"
              @click="cancelappsearchMode()"
            ></button>
            <button
              type="button"
              @click="addVendorPages()"
              class="btn btn-light float-end mb-4"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>
        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-3">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("user_wallet_withdrow_requests.id") }}</th>
              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.user_id") }}
              </th>

              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.tel") }}
              </th>

              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.email") }}
              </th>

              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.amount") }}
              </th>
              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.status_id") }}
              </th>
              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.created") }}
              </th>
              <th scope="col">
                {{ $t("user_wallet_withdrow_requests.updated") }}
              </th>
              <th scope="col">
                {{ $t("popups.operations") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(app, index) in drivers" :key="app" class="text-center">
              <td>{{ index + 1 }}</td>

              <td>{{ app?.user?.name }}</td>
              <td>{{ app?.user?.tel }}</td>
              <td>{{ app?.user?.email }}</td>
              <td>{{ app.amount }}</td>
              <td>
                {{
                  $i18n.locale == "ar"
                    ? app?.status?.name_ar
                    : app?.status?.name
                }}
              </td>
              <td>{{ app.created.split("T")[0] }}</td>
              <td>{{ app.updated.split("T")[0] }}</td>

              <td class="d-flex">
               <a
                  @click="showModel()"
                  class="btn btn-success mx-2"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.accept") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="changeStatus(app, 3)"
                  >{{ $t("popups.reject") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>

        <!-- end  
      
       <a v-if="app.status == 1" class="btn btn-danger mx-1" @click="changeStates(app)" href="javascript: void(0);"
              role="button">{{ $t("popups.disable") }}</a>
            <a v-if="app.status == 0" class="btn btn-primary mx-1" @click="changeStates(app)" href="javascript: void(0);"
              role="button">{{ $t("popups.enable") }}</a>
      
        table-->
        <!-- Button trigger modal -->

        <div v-if="tot_pages == 20">
          <!--   Apps  paginate     -->
          <ul class="paginate paginate-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: p == page }"
              v-for="p in tot_pages"
              :key="p"
            >
              <a class="page-link" href="javascript: void(0);" @click="get(p)">
                {{ p }}
              </a>
            </li>

            <li class="page-item" :class="{ disabled: page == total_pages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </b-tab>
    </b-tabs>

    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




